// @keyframes Rotate {
//   0% {
//     transform: rotate(180deg);
//   }
//   100% {
//     transform: rotate(540deg);
//   }
// }

// #loader{
// 	width: 100%;
// 	height: 100%;
// 	position: fixed;
// 	top:0;
// 	z-index: 999;
// 	background: #fff;
// 	overflow: hidden;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
	
// 	visibility: hidden;
// 	opacity: 1;
// 	transition: 0.5s ease;
	
// 	&.loader{
// 		transition:none !important;
// 		visibility:visible;
// 		&:after{
// 			content: "";
// 			border: 4px solid #ccc;
// 			// Zmienić na poczatku pracy na kolor z base:
// 			border-top: 8px solid #21201f;
// 			border-radius: 100%;
// 			height: 60px;
// 			width: 60px;
// 			position: absolute;
// 			transition: .02s ease-out;
// 			animation: Rotate .7s infinite cubic-bezier(.2,.8,.8,.2); 
// 		}	
// 	}
// }

@keyframes Slide {
	0% {
		transform: translateY(-100px);
		margin: 0 0 10px;
		opacity: 0.5;
	}
	50% {
		transform: translateY(0px);
		margin: 0 0 3px;
		opacity: 1;
	}
	100% {
		transform: translateY(100px);
		margin: 0 0 10px;
		opacity: 0;
	}
}

#loader {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	z-index: 999;
	background: #fff;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;

	visibility: hidden;
	opacity: 1;
	transition: 0.5s ease;

	&.loader {
		transition: none !important;
		visibility: visible;
		.loader__wrap {
			height: 100px;
			> div {
				opacity: 0;
				width: 37px;
				background: #0D1E3F;
				animation: Slide 2s infinite;
				&:nth-child(1) {
					height: 0.5px;
				}
				&:nth-child(2) {
					height: 1px;
				}
				&:nth-child(3) {
					height: 2px;
				}
				&:nth-child(4) {
					height: 3px;
				}
				&:nth-child(5) {
					height: 4px;
				}
			}
		}
	}
}